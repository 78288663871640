import axios from 'axios'
// const phpAddress = 'http://127.0.0.1:8000'
const phpAddress = process.env.VUE_APP_PHP_API
const nodeAddress = 'http://localhost:8082'

const client = axios.create({
  baseURL: nodeAddress,
  json: true
})

const clientPhp = axios.create({
  baseURL: phpAddress,
  json: true
})

export default {
  async execute (method, resource, data) {
    return client({
      method,
      url: resource,
      data
    }).then(req => {
      return req.data
    })
  },
  async executePhp (method, resource, data, headers) {
    return clientPhp({
      method,
      url: resource,
      data,
      headers
    }).then(req => {
      return req.data
    })
  },
  getSector () {
    return this.executePhp('post', '/api/kjn/master/sector/all?limit=100')
  },
  getSubsector () {
    return this.executePhp('post', '/api/kjn/master/subsector/all?limit=9999')
  },
  getKbliData () {
    return this.executePhp('post', '/api/kjn/master/kbli/all?limit=9999')
  },
  getKodeJabatan () {
    return this.executePhp('post', '/api/kjn/jabatan/all?limit=9999')
  },
  addProfile (params, headers) {
    return this.executePhp('put', `/api/user/info/update/${params.u_id}`, params, headers)
  },
  getProfilePengusul (userId, params, headers) {
    return this.executePhp('post', `/api/user/info/show/${userId}`, params, headers)
  },
  submitUsulan (payload, headers) {
    return this.executePhp('post', `/api/kjn/usulan/add`, payload, headers)
  }
}
