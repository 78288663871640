<template>
  <div class="pt-5">

    <section class="container mb-5">
      <div class="card shadow-sm border-0 mt-5">
        <div class="row justify-content-start p-4">
          <div class="col-3 mb-4">
            <h2>PROFIL PENGUSUL</h2>
            <h6>
              <i class="bi bi-c-circle"></i>
              e-Jabatan 2022
            </h6>
          </div>
          <div class="col-9 shadow-sm">
            <div class="form-section">
              <h4>
                Jenis Pengusul
              </h4>
              <div class="mb-3">
                <el-checkbox v-model="isInstansi">
                  <span class="font-kategori">a. Instansi</span>
                </el-checkbox>
                <div v-show="isInstansi" class="mt-2">
                  <div class="form-section">
                    <h6>
                      Kategori/Lapangan Usaha<i
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-custom-class="custom-tooltip"
                        data-bs-title="Berdasarkan KBLI 2020"
                      >*</i>:
                      </h6>
                    <el-select
                      v-model="selectedSector"
                      placeholder="- Pilih Kategori/Lapangan Usaha -"
                      filterable
                      no-match-text="Tidak ada data yang sesuai"
                      no-data-text="Tidak ada data"
                      @change="filterKbli('instansi')"
                    >
                      <el-option
                        v-for="item in master.sector"
                        :key="item.id"
                        :label="`(${item.kode}) ${item.sector}`"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="form-section">
                    <h6>
                      Kode KBLI*:
                    </h6>
                    <el-select
                      :disabled="selectedSector ? false : true"
                      v-model="selectedKodeKbli"
                      placeholder="- Pilih KBLI -"
                      filterable
                      clearable
                      no-match-text="Tidak ada data yang sesuai"
                      no-data-text="Tidak ada data"
                      remote
                      :remote-method="kbliSearch"
                    >
                      <el-option
                        v-for="item in kodeKbliOptions"
                        :key="item.kode"
                        :label="`${item.kode} - ${item.kbli}`"
                        :value="item.kode"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <h6>Nama Instansi*:</h6>
                  <el-input class="mb-3" placeholder="Please input" v-model="instansi"></el-input>
                  <h6>Alamat Instansi*:</h6>
                  <el-input class="mb-3" placeholder="Please input" v-model="alamatInstansi"></el-input>
                  <h6>Alamat Web*:</h6>
                  <el-input placeholder="https://" v-model="alamatWeb"></el-input>
                </div>
              </div>
              <div class="mb-3">
                <el-checkbox v-model="isPerorangan">
                  <span class="font-kategori">b. Perorangan</span>
                </el-checkbox>
                <div v-show="isPerorangan" class="mt-2">
                  <div class="form-section">
                    <h6>
                      Kategori/Lapangan Usaha<i
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-custom-class="custom-tooltip"
                        data-bs-title="Berdasarkan KBLI 2020"
                      >*</i>:
                    </h6>
                    <el-select
                      v-model="selectedSector2"
                      placeholder="- Pilih Kategori/Lapangan Usaha -"
                      filterable
                      no-match-text="Tidak ada data yang sesuai"
                      no-data-text="Tidak ada data"
                      @change="filterKbli('perorangan')"
                    >
                      <el-option
                        v-for="item in master.sector"
                        :key="item.id"
                        :label="`(${item.kode}) ${item.sector}`"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="form-section">
                    <h6>
                      Kode KBLI:
                    </h6>
                    <el-select
                      :disabled="selectedSector2 ? false : true"
                      v-model="selectedKodeKbli2"
                      placeholder="- Pilih KBLI -"
                      filterable
                      clearable
                      no-match-text="Tidak ada data yang sesuai"
                      no-data-text="Tidak ada data"
                      remote
                      :remote-method="kbliSearch"
                    >
                      <el-option
                        v-for="item in kodeKbliOptions2"
                        :key="item.kode"
                        :label="`${item.kode} - ${item.kbli}`"
                        :value="item.kode"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <h6>Jenis Pekerjaan:</h6>
                  <el-input placeholder="Freelancer, Konsultan, dll" v-model="pekerjaan"></el-input></div>
              </div>
              <div class="mb-3">
                <el-checkbox v-model="isPengantarKerja">
                  <span class="font-kategori">c. Pengantar Kerja</span>
                </el-checkbox>
              </div>
            </div>
            <div class="d-flex justify-content-end my-3">
              <el-button
                style="background-color: #15406A; color: white;"
                @click="addForm"
              >
                Simpan
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from '../informasiJabatan/api.js'
import auth from '../../helpers/auth'
import swal from 'sweetalert2'
import {
  Select,
  Option,
  Loading,
  Input,
  Radio,
  RadioGroup,
  Button,
  Checkbox
} from 'element-ui'

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Loading.name]: Loading,
    [Input.name]: Input,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox
  },
  data () {
    return {
      master: {
        sector: [],
        kodeKbli: []
      },
      selectedSector: '',
      selectedKodeKbli: '',
      selectedSector2: '',
      selectedKodeKbli2: '',
      kodeKbliOptions: [],
      kodeKbliOptions2: [],
      isInstansi: true,
      isPerorangan: false,
      isPengantarKerja: false,
      instansi: '',
      alamatInstansi: '',
      alamatWeb: '',
      pekerjaan: '',
      tooltipTriggerList: null,
      tooltipList: null,
    }
  },
  computed: {
    form () {
      const form = {
        u_role: this.isInstansi ? 5 : (this.isPerorangan ? 6 : 7),
        u_id: auth.getUser().id,
        u_kbli_kategory: this.isInstansi ? this.selectedSector : this.selectedSector2,
        u_kbli_kode: this.isInstansi ? this.selectedKodeKbli : this.selectedKodeKbli2,
        u_nama_lengkap: auth.getUser().name,
        u_nama_instansi: this.instansi,
        u_alamat_instansi: this.alamatInstansi,
        u_website_instansi: this.alamatWeb,
        u_status_pekerjaan: this.pekerjaan,
        token: auth.getSSOToken()
      }
      return form
    },
    validate () {
      const error = []
      if (!this.isInstansi && !this.isPerorangan && !this.isPengantarKerja) {
        error.push('Salah satu jenis pengusul wajib diisi')
      }
      if (this.isInstansi) {
        if (!this.selectedSector) {
          error.push('Kategori/Lapangan Usaha wajib diisi')
        }
        if (!this.selectedKodeKbli) {
          error.push('Kode KBLI wajib diisi')
        }
        if (!this.instansi) {
          error.push('Nama Instansi wajib diisi')
        }
        if (!this.alamatInstansi) {
          error.push('Alamat Instansi wajib diisi')
        }
        if (!this.alamatWeb) {
          error.push('Alamat Web wajib diisi')
        }
        if (!this.alamatWeb.includes('https://')) {
          error.push('Masukkan alamat web dengan benar')
        }
      }
      if (this.isPerorangan) {
        if (!this.selectedSector2) {
          error.push('Kategori Lapangan / Usaha wajib diisi')
        }
        if (!this.selectedKodeKbli2) {
          error.push('Kode KBLI wajib diisi')
        }
        if (!this.pekerjaan) {
          error.push('Pekerjaan wajib diisi')
        }
      }
      return error
    }
  },
  async mounted () {
    await this.getMasterData()
    await this.getProfile()
    this.tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    this.tooltipList = [...this.tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
  },
  methods: {
    async getMasterData () {
      Loading.service()
      try {
        const { data: sectorData } = await api.getSector()
        const { data: kbliData } = await api.getKbliData()
        this.master.sector = sectorData
        this.master.kodeKbli = kbliData
        this.kodeKbliOptions = kbliData.slice(0, 50)
        this.kodeKbliOptions2 = kbliData.slice(0, 50)
      } catch (error) {
        console.log(error)
      } finally {
        Loading.service().close()
      }
    },
    filterKbli (jenis) {
      if (jenis === 'instansi') {
        this.kodeKbliOptions = this.master.kodeKbli.filter(item => item.sector_id === this.selectedSector).slice(0, 50)
      } else if (jenis === 'perorangan') {
        this.kodeKbliOptions2 = this.master.kodeKbli.filter(item => item.sector_id === this.selectedSector2).slice(0, 50)
      }
    },
    kbliSearch (query) {
      if (this.isInstansi) {
        if (query === '') {
          this.kodeKbliOptions = this.master.kodeKbli.slice(0, 50)
        } else {
          this.kodeKbliOptions = this.master.kodeKbli.filter(data => data.kbli.toLowerCase().includes(query.toLowerCase())).slice(0, 50)
        }
      } else if (this.isPerorangan) {
        if (query === '') {
          this.kodeKbliOptions2 = this.master.kodeKbli.slice(0, 50)
        } else {
          this.kodeKbliOptions2 = this.master.kodeKbli.filter(data => data.kbli.toLowerCase().includes(query.toLowerCase())).slice(0, 50)
        }
      }
    },
    async addForm () {
      try {
        const err = this.validate
        if (err.length > 0) {
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: err.join('<br>')
          })
          return
        }
        const res = await api.addProfile(this.form, { Authorization: `Bearer ${auth.getToken()}` })
        console.log(res, 'res')
        if (res.user.updated === true) {
          swal.fire(
            'Success',
            'Berhasil menambahkan data',
            'success'
          )
          auth.setLogin(res.user.data[0])
          await this.getProfile()
        }
      } catch (error) {
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: error
        })
      }
    },
    async getProfile () {
      try {
        const userId = auth.getUser().id
        const headers = { Authorization: `Bearer ${auth.getToken()}` }
        const payload = {
          token: auth.getSSOToken()
        }
        const res = await api.getProfilePengusul(userId, payload, headers)
        const data = res[0]
        if (auth.getUser().is_active === '0') {
          swal.fire(
            'Lengkapi profil terlebih dahulu'
          )
        } else {
          if (data.role_id === 5) {
            this.isInstansi = true
            this.instansi = data.instansi_name
            this.alamatInstansi = data.instansi_address
            this.alamatWeb = data.instansi_website
            this.selectedSector = +data.kategori_kbli
            this.filterKbli('instansi')
            this.selectedKodeKbli = data.kode_kbli
          } else if (data.role_id === 6) {
            this.isPerorangan = true
            this.pekerjaan = data.status_pekerjaan
            this.selectedSector2 = +data.kategori_kbli
            this.filterKbli('perorangan')
            this.selectedKodeKbli2 = data.kode_kbli
          } else if (data.role_id === 7) {
            this.isPengantarKerja = true
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  watch: {
    isInstansi: function (val) {
      if (val === true) {
        this.isPerorangan = false
        this.isPengantarKerja = false
      }
    },
    isPerorangan: function (val) {
      if (val === true) {
        this.isInstansi = false
        this.isPengantarKerja = false
      }
    },
    isPengantarKerja: function (val) {
      if (val === true) {
        this.isInstansi = false
        this.isPerorangan = false
      }
    }
  }
}
</script>

<style>
.el-select-dropdown {
  z-index: 9001 !important;
}

.el-select {
  width: 100%;
}
.tooltip-inner {
  text-align: left;
  max-width: 20vw !important;
}
.card-profile {
  display: flex;
  justify-content: center;
  padding: 1rem;

}
</style>
<style scoped>
.form-section {
  margin-bottom: 15px;
}
.font-kategori {
  font-size: 18px;
  color: #212529;
}
</style>
